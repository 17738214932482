import * as React from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { Layout } from '../components/Layout';
import HeroSection from '../components/Roadmap/HeroSection';
import DescriptionSection from '../components/Roadmap/DescriptionSection';
import PathSection from '../components/Roadmap/PathSection';

const Roadmap = ({ data = {} }) => {
  const {
    prismicRoadmap: {
      data: {
        title,
        description,
        og_image: ogImage,
        hero_heading: { text: heroHeading },
        hero_subheading: { text: heroSubheading },
        hero_cards_heading: { text: heroCardsHeading },
        hero_cards: heroCards,
        description_text: { text: descriptionText },
        description_masternode_button_text: descriptionMasternodeButtonText,
        description_masternode_button_link: descriptionMasternodeButtonLink,
        description_staking_button_text: descriptionStakingButtonText,
        description_staking_button_link: descriptionStakingButtonLink,
        description_source_button_text: descriptionSourceButtonText,
        description_source_button_link: descriptionSourceButtonLink,
        path_heading: { text: pathHeading },
        path_steps: pathSteps,
        path_full_button: pathFullButtonLink,
      },
    },
  } = data;

  return (
    <Layout seo={{ title, description, ogImage }}>
      <HeroSection
        {...{ heroHeading, heroSubheading, heroCardsHeading, heroCards }}
      />
      <DescriptionSection
        {...{
          descriptionText,
          descriptionMasternodeButtonText,
          descriptionMasternodeButtonLink,
          descriptionStakingButtonText,
          descriptionStakingButtonLink,
          descriptionSourceButtonText,
          descriptionSourceButtonLink,
        }}
      />
      <PathSection
        {...{
          pathHeading,
          pathSteps,
          pathFullButtonLink,
        }}
      />
    </Layout>
  );
};

export default withPrismicPreview(Roadmap);

export const query = graphql`
  {
    prismicRoadmap {
      data {
        title
        description
        og_image {
          url
          dimensions {
            height
            width
          }
        }
        hero_heading {
          text
        }
        hero_subheading {
          text
        }
        hero_cards_heading {
          text
        }
        hero_cards {
          big_text {
            text
          }
          small_text {
            text
          }
        }
        description_text {
          text
        }
        description_masternode_button_text
        description_masternode_button_link {
          url
          uid
          target
          link_type
        }
        description_staking_button_text
        description_staking_button_link {
          url
          uid
          target
          link_type
        }
        description_source_button_text
        description_source_button_link {
          url
          uid
          target
          link_type
        }
        path_heading {
          text
        }
        path_steps {
          isdone
          text
          notice {
            text
          }
        }
        path_full_button {
          url
          link_type
          target
        }
      }
      _previewable
    }
  }
`;
