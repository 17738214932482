import * as React from 'react';
import Arc from '../../assets/common-illustrations/arc.png';
import Bagel from '../../assets/common-illustrations/bagel.png';

const HeroSection = ({
  heroHeading = '',
  heroSubheading = '',
  heroCardsHeading = '',
  heroCards = [{}],
}) => (
  <section className="roadmap-hero">
    <div className="content">
      <h1 className="roadmap-hero__heading">{heroHeading}</h1>
      <h2 className="roadmap-hero__subheading">{heroSubheading}</h2>
      <h2 className="roadmap-hero__cards-heading">{heroCardsHeading}</h2>
      <div className="roadmap-hero__cards">
        {heroCards.map(
          (
            { big_text: { text: bigText }, small_text: { text: smallText } },
            i,
          ) => (
            <div className="roadmap-hero__card" key={`roadmap-card-${i}`}>
              <h4 className="roadmap-hero__card-text roadmap-hero__card-text_bigger">
                {bigText}
              </h4>
              <h5 className="roadmap-hero__card-text roadmap-hero__card-text_smaller">
                {smallText}
              </h5>
            </div>
          ),
        )}
      </div>
    </div>
    <img src={Arc} alt="#" className="roadmap-hero__arc" />
    <img src={Bagel} alt="#" className="roadmap-hero__bagel" />
  </section>
);

export default HeroSection;
