import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import HalfSphere from '../../assets/common-illustrations/half-sphere.svg';
import Arc from '../../assets/common-illustrations/arc.png';
import Sphere from '../../assets/common-illustrations/sphere.svg';
import Bagel from '../../assets/common-illustrations/bagel.png';
import Pyramid from '../../assets/common-illustrations/pyramid.svg';
import PrismicLink from '../PrismicLink';

const PathSection = ({
  pathHeading = '',
  pathSteps = [{}],
  pathFullButtonLink = {},
}) => (
  <section className="path">
    <div className="content path__content">
      <h2 className="path__heading">{pathHeading}</h2>
      <div className="path__steps">
        <div className="path__step path__step_first" />
        {pathSteps.map((item, i) => (
          <Step {...item} key={`path-step-${i}`} />
        ))}
      </div>
    </div>
    <PrismicLink
      className="button button_white path__button"
      link={pathFullButtonLink}
    >
      ↖ Full Version
    </PrismicLink>
    <div className="path__wrapper path__wrapper_half-sphere">
      <HalfSphere className="path__half-sphere" />
    </div>
    <div className="path__wrapper path__wrapper_arc">
      <img src={Arc} alt="#" className="path__arc" />
    </div>
    <div className=" path__wrapper path__wrapper_sphere">
      <Sphere className="path__sphere" />
    </div>
    <div className="path__wrapper path__wrapper_bagel">
      <img src={Bagel} alt="#" className="path__bagel" />
    </div>
    <div className="path__wrapper path__wrapper_pyramid">
      <Pyramid className="path__pyramid" />
    </div>
  </section>
);

const Step = ({
  isdone: isDone = false,
  text = '',
  notice: { text: notice = '' },
}) => {
  const element = useRef(null);
  const [isInMiddle, setIsInMiddle] = useState(false);
  useEffect(() => {
    const func = () => {
      const middle = window.innerHeight / 2 - 100;
      const { top } = element.current.getBoundingClientRect();
      setIsInMiddle(top > middle - 150 && top < middle + 150);
    };

    document.addEventListener('scroll', func);
    return () => {
      document.removeEventListener('scroll', func);
    };
  }, []);

  return (
    <div
      ref={element}
      className={`path__step ${isDone ? 'path__step_done' : ''} ${
        isInMiddle ? 'path__step_accent' : ''
      }`}
    >
      <h3 className="path__step-heading">{text}</h3>
      {notice ? <h4 className="path__step-subheading">{notice}</h4> : null}
    </div>
  );
};

export default PathSection;
