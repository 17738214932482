module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"ambrosus-landing","accessToken":"MC5ZWEZaM2hRQUFDY0FOdTdx.O--_ve-_vXRra--_vSrvv71m77-977-9RDE3QAkv77-977-9BO-_ve-_ve-_ve-_ve-_vVtLfwBo77-9","promptForAccessToken":true,"apiEndpoint":"https://ambrosus-landing.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max","q":50},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
