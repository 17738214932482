import * as React from 'react';
import {
  componentResolverFromMap,
  PrismicPreviewProvider,
} from 'gatsby-plugin-prismic-previews';

import { linkResolver } from './src/utils/linkResolver';
import IndexPage from './src/pages/index';

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
        linkResolver,
        componentResolver: componentResolverFromMap({
          homepage: IndexPage,
        }),
      },
    ]}
  >
    {element}
  </PrismicPreviewProvider>
);
