import * as React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({
  title = 'Ambrosus Blockchain Solution',
  description = 'The blockchain for IoT and supply chain.',
  ogImage = {
    url: '',
    dimensions: { height: null, width: null },
  },
}) => {
  const image = ogImage.url
    ? ogImage
    : {
        url: 'https://roadmap.ambrosus.io/roadmap-og.png',
        dimensions: { height: 600, width: 1200 },
      };

  return (
    <Helmet>
      {/*  Title  */}
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta name="twitter:title" content={title} />

      {/*  Description  */}
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta name="twitter:description" content={description} />

      {/* Image */}
      <meta property="og:image" content={image.url} />
      <meta name="twitter:image" content={image.url} />
      <meta property="og:image:width" content={image.dimensions.width} />
      <meta property="og:image:height" content={image.dimensions.height} />

      {/* Twitter specific */}
      <meta name="twitter:site" content="@Amb_Ecosystem" />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  );
};

export default SEO;
