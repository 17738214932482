import * as React from 'react';
import { Header } from './Header';
import { Footer } from './Footer';
import SEO from './SEO';

import '../styles/Main.scss';

export const Layout = ({ children, seo }) => (
  <>
    <SEO {...seo} />
    <Header />
    <main>{children}</main>
    <Footer />
  </>
);
