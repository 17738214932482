import * as React from 'react';
import PropTypes from 'prop-types';

const PrismicLink = ({
  link: { url, target, link_type: linkType },
  children,
  ...restProps
}) =>
  linkType === 'Document' ? (
    <a href={`https://ambrosus.io${url}`} {...restProps}>
      {children}
    </a>
  ) : (
    <a
      href={url}
      target={linkType === 'Media' ? '_blank' : target}
      {...restProps}
    >
      {children}
    </a>
  );

PrismicLink.propTypes = {
  link: PropTypes.shape({
    uid: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    url: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    target: PropTypes.oneOf([null, '_blank']),
    link_type: PropTypes.oneOf(['Document', 'Web', 'Media']),
  }),
  children: PropTypes.node,
};

export default PrismicLink;
