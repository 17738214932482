import * as React from 'react';
import { useState } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Logo from '../assets/svg/logo.svg';
import MetamaskIcon from '../assets/svg/metamask-menu-icon.svg';
import PrismicLink from './PrismicLink';
import { MobileMenu } from './MobileMenu';
import addNetworkToMetamask from '../utils/addNetworkToMetamask';

export const Header = () => {
  const {
    prismicHeader: { data },
  } = useStaticQuery(
    graphql`
      {
        prismicHeader {
          _previewable
          data {
            first_submenu_items {
              name {
                text
              }
              link {
                url
                target
                link_type
              }
            }
            first_submenu_name
            second_item_name
            third_submenu_items {
              name {
                text
              }
              link {
                url
                target
                link_type
              }
            }
            third_submenu_name
            fourth_item_name
            fourth_item_link {
              url
              target
              link_type
            }
            second_submenu_items {
              link {
                url
                target
                link_type
                slug
              }
              name {
                text
              }
            }
            fifth_submenu_name
            fifth_submenu_items {
              link {
                url
                target
                link_type
              }
              name {
                text
              }
            }
          }
        }
      }
    `,
  );

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <>
      <HeaderLayout
        {...{
          data,
          toggleMenu,
          isOpen,
        }}
      />
      <MobileMenu
        {...{
          data,
          isOpen,
          toggleMenu,
        }}
      />
    </>
  );
};

const HeaderLayout = ({
  data: {
    first_submenu_items: firstSubmenuItems = [{}],
    first_submenu_name: firstSubmenuName = '',
    second_submenu_items: secondSubmenuItems = [{}],
    second_item_name: secondSubmenuName = '',
    third_submenu_items: thirdSubmenuItems = [{}],
    third_submenu_name: thirdSubmenuName = '',
    fourth_item_name: fourthItemName = '',
    fourth_item_link: fourthItemLink = {},
    fifth_submenu_name: fifthSubmenuName = '',
    fifth_submenu_items: fifthSubmenuItems = [{}],
  },
  isOpen = false,
  toggleMenu = () => {},
}) => (
  <header className="header">
    <div className="content header__content">
      <Link to="/" className="header__logo-wrapper">
        <Logo className="header__logo" />
      </Link>

      <Submenu name={firstSubmenuName} items={firstSubmenuItems} />
      <Submenu name={secondSubmenuName} items={secondSubmenuItems} />

      <Submenu name={thirdSubmenuName} items={thirdSubmenuItems} />

      <PrismicLink link={fourthItemLink} className="header__link">
        {fourthItemName}
      </PrismicLink>

      <Submenu
        showAddMetamaskButton
        isLastItem
        name={fifthSubmenuName}
        items={fifthSubmenuItems}
      />

      <button
        type="button"
        className={`burger-icon ${isOpen ? 'burger-icon_open' : ''}`}
        onClick={toggleMenu}
      >
        <span className="burger-icon__first-line burger-icon__line" />
        <span className="burger-icon__second-line burger-icon__line" />
        <span className="burger-icon__third-line burger-icon__line" />
      </button>
    </div>
  </header>
);

const Submenu = ({
  name = '',
  items = [{}],
  showAddMetamaskButton = false,
  isLastItem = false,
}) => (
  <div
    className={`submenu ${isLastItem ? 'submenu_last' : ''} ${
      showAddMetamaskButton ? 'submenu_metamask' : ''
    }`}
  >
    <p className="submenu__name">
      {name}
      <svg viewBox="0 0 15 8" className="submenu__arrow" fill="none">
        <path
          d="M14.2031 1L7.53644 7L0.869791 0.999999"
          stroke="currentColor"
        />
      </svg>
    </p>
    <div
      className="submenu__items"
      style={{
        '--items-amount': showAddMetamaskButton
          ? items.length + 1
          : items.length,
      }}
    >
      {showAddMetamaskButton ? (
        <button
          type="button"
          className="submenu__item submenu__item_metamask"
          onClick={addNetworkToMetamask}
        >
          <MetamaskIcon />
          Add Ambrosus Network to Metamask
        </button>
      ) : null}

      {items.map(({ name: { text: itemName }, link }) => (
        <PrismicLink link={link} key={link.url} className="submenu__item">
          {itemName}
        </PrismicLink>
      ))}
    </div>
  </div>
);
