import * as React from 'react';
import PrismicLink from '../PrismicLink';
import Arrow from '../../assets/svg/arrow.svg';

const DescriptionSection = ({
  descriptionText = '',
  descriptionMasternodeButtonText = '',
  descriptionMasternodeButtonLink = {},
  descriptionStakingButtonText = '',
  descriptionStakingButtonLink = {},
  descriptionSourceButtonText = '',
  descriptionSourceButtonLink = {},
}) => (
  <section className="roadmap-description">
    <div className="content roadmap-description__content">
      <p className="roadmap-description__text">{descriptionText}</p>
      <PrismicLink
        link={descriptionMasternodeButtonLink}
        className="button button_white roadmap-description__button desktop-only"
      >
        {descriptionMasternodeButtonText}
      </PrismicLink>
      <PrismicLink
        link={descriptionStakingButtonLink}
        className="button button_white roadmap-description__button desktop-only"
      >
        {descriptionStakingButtonText}
      </PrismicLink>
      <PrismicLink
        link={descriptionSourceButtonLink}
        className="button button_black roadmap-description__button"
      >
        {descriptionSourceButtonText}
      </PrismicLink>
      <div className="roadmap-description__mobile-buttons">
        <PrismicLink
          link={descriptionMasternodeButtonLink}
          className="roadmap-description__link"
        >
          Run a masternode
          <Arrow className="roadmap-description__link-arrow" />
        </PrismicLink>
        <PrismicLink
          link={descriptionStakingButtonLink}
          className="roadmap-description__link"
        >
          Simplified Staking
          <Arrow className="roadmap-description__link-arrow" />
        </PrismicLink>
      </div>
    </div>
  </section>
);

export default DescriptionSection;
