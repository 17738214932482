import * as React from 'react';
import { useEffect, useState } from 'react';
import PrismicLink from './PrismicLink';
import MetamaskIcon from '../assets/svg/metamask-menu-icon.svg';
import addNetworkToMetamask from '../utils/addNetworkToMetamask';

export const MobileMenu = ({
  data: {
    first_submenu_items: firstSubmenuItems = [{}],
    first_submenu_name: firstSubmenuName = '',
    second_submenu_items: secondSubmenuItems = [{}],
    second_item_name: secondSubmenuName = '',
    third_submenu_items: thirdSubmenuItems = [{}],
    third_submenu_name: thirdSubmenuName = '',
    fourth_item_name: fourthItemName = '',
    fourth_item_link: fourthItemLink = {},
    fifth_submenu_name: fifthSubmenuName = '',
    fifth_submenu_items: fifthSubmenuItems = [{}],
  },
  isOpen = false,
  toggleMenu = () => {},
}) => {
  const [openSubmenuIndex, setOpenSubmenuIndex] = useState(-1);

  useEffect(() => {
    if (isOpen) document.querySelector('body').style.overflow = 'hidden';
    else document.querySelector('body').style.overflow = '';
  }, [isOpen]);

  const toggleSubmenu = (index) =>
    setOpenSubmenuIndex(index === openSubmenuIndex ? -1 : index);

  return (
    <div className={`mobile-menu ${isOpen ? 'mobile-menu_open' : ''}`}>
      <MobileSubmenu
        items={firstSubmenuItems}
        name={firstSubmenuName}
        index={1}
        toggleSubmenu={() => toggleSubmenu(1)}
        isOpen={openSubmenuIndex === 1}
      />

      <MobileSubmenu
        items={secondSubmenuItems}
        name={secondSubmenuName}
        index={2}
        toggleSubmenu={() => toggleSubmenu(2)}
        isOpen={openSubmenuIndex === 2}
      />

      <MobileSubmenu
        items={thirdSubmenuItems}
        name={thirdSubmenuName}
        index={3}
        toggleSubmenu={() => toggleSubmenu(3)}
        toggleMenu={toggleMenu}
        isOpen={openSubmenuIndex === 3}
      />

      <PrismicLink
        link={fourthItemLink}
        className="mobile-menu__link"
        data-number={`—\u00A00${4}`}
        onClick={toggleMenu}
      >
        {fourthItemName}
      </PrismicLink>

      <MobileSubmenu
        items={fifthSubmenuItems}
        name={fifthSubmenuName}
        index={5}
        toggleSubmenu={() => toggleSubmenu(5)}
        toggleMenu={toggleMenu}
        isOpen={openSubmenuIndex === 5}
        showAddMetamaskButton
      />
    </div>
  );
};

const MobileSubmenu = ({
  name = '',
  items = [{}],
  index = 0,
  toggleSubmenu = () => {},
  toggleMenu = () => {},
  isOpen = false,
  showAddMetamaskButton = false,
}) => (
  <div className={`mobile-submenu ${isOpen ? 'mobile-submenu_open' : ''}`}>
    <button
      type="button"
      className="mobile-submenu__name"
      data-number={`—\u00A00${index}`}
      onClick={toggleSubmenu}
    >
      {name}
      <svg viewBox="0 0 15 8" className="mobile-submenu__arrow" fill="none">
        <path
          d="M14.2031 1L7.53644 7L0.869791 0.999999"
          stroke="currentColor"
        />
      </svg>
    </button>
    <div
      className={`mobile-submenu__items ${
        showAddMetamaskButton ? 'mobile-submenu__items_metamask' : ''
      }`}
      style={{
        '--items-amount': items.length,
      }}
    >
      {showAddMetamaskButton ? (
        <button
          type="button"
          className="mobile-submenu__item mobile-submenu__item_metamask"
          onClick={addNetworkToMetamask}
        >
          <MetamaskIcon className="mobile-submenu__metamask-icon" />
          Add to Metamask
        </button>
      ) : null}
      {items.map(({ name: { text: itemName }, link }) => (
        <PrismicLink
          link={link}
          key={link.url}
          className="mobile-submenu__item"
          onClick={toggleMenu}
        >
          {itemName}
        </PrismicLink>
      ))}
    </div>
  </div>
);
